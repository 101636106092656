import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Image from 'react-bootstrap/Image'
import Button from 'react-bootstrap/Button'

function Section3({data,events,language, direction}) {
    function openurl(url) {
        window.location.href = url
    }
    return (
        <Container id="section3" className="section3 scroll-div scroll-mt px-section px-0 pb-5" dir={direction}>
            <div className="text-center">
                <div className="px-4">
                    <h1 className={"secondary"+language}>{language === 'AR' ? 'خدمات الضيافة' : data.title}</h1>
                    <p className={"py-3 primary"+language}>{language === 'AR' ? 'يقدم لكم ضيافة أواني تجربة طعام مميزة تناسب جميع مناسباتكم، مع خيارات متنوعة تلبي احتياجاتكم – سواء كان ذلك من خلال قوائم طعام محددة، بوفيه غني، عشاء فاخر بخدمة الطاولة، أو محطات طهي حية تفاعلية. استمتعوا بالنكهات الأصيلة والخدمة الراقية التي تضيف لمسة خاصة لكل مناسبة.' : data.description}</p>
                </div>
                <div className="text-center">
                    <Button variant="primary" className="rounded-pill text-uppercase mobile-btn primary-bg" onClick={() => openurl("https://awjftp.blob.core.windows.net/awjcontents/websites/awani/AwaniCateringMenu1.2.pdf")}>{language === 'AR' ? 'قائمة الطعام' : 'Catering Menu'}</Button>
                </div>
            </div>
            <Row className="py-5 mx-auto w-100">
                <Col className="px-2">
                    <div className="section3-img">
                        <Image src={"https://sqlvas774pizbiy4km.blob.core.windows.net/liveenvironment/"+events[0].image} className="w-100" />
                        {/* <div className="overlay">
                            <div className="overlay-content">
                                <p>{events[0].title}</p>
                            </div>
                        </div> */}
                    </div>
                </Col>
                <Col className="px-2">
                    <div className="section3-img">
                        <Image src={"https://sqlvas774pizbiy4km.blob.core.windows.net/liveenvironment/"+events[1].image} className="w-100" />
                        {/* <div className="overlay">
                            <div className="overlay-content">
                                <p>{events[1].title}</p>
                            </div>
                        </div> */}
                    </div>
                </Col>
            </Row>
            <Row className="mx-auto w-100">
                <Col md={8} className="px-2">
                    <div className="section3-img">
                        <Image src={"https://sqlvas774pizbiy4km.blob.core.windows.net/liveenvironment/"+events[2].image} className="w-100 mobile-mb-1" />
                        {/* <div className="overlay">
                            <div className="overlay-content">
                                <p>{events[2].title}</p>
                            </div>
                        </div> */}
                    </div>
                </Col>
                <Col md={4} className="px-2">
                    <div className="section3-img">
                        <Image src={"https://sqlvas774pizbiy4km.blob.core.windows.net/liveenvironment/"+events[3].image} className="w-100 mb-1 mobile-mt-2" />
                        {/* <div className="overlay">
                            <div className="overlay-content">
                                <p>{events[3].title}</p>
                            </div>
                        </div> */}
                    </div>
                    <div className="section3-img">
                        <Image src={"https://sqlvas774pizbiy4km.blob.core.windows.net/liveenvironment/"+events[4].image} className="w-100 mt-2" />
                        {/* <div className="overlay">
                            <div className="overlay-content">
                                <p>{events[4].title}</p>
                            </div>
                        </div> */}
                    </div>
                </Col>
            </Row>
        </Container >
    );
}
export default Section3;